var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "div",
        { staticClass: "d-flex flex-wrap align-center" },
        _vm._l(_vm.availableQueryTypes, function(queryType) {
          return _c(
            "div",
            { key: queryType },
            [
              _c("v-checkbox", {
                staticClass: "mx-4",
                attrs: { label: queryType },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "label",
                      fn: function() {
                        return [
                          _c(
                            "span",
                            {
                              staticClass:
                                "primary--text caption text-uppercase"
                            },
                            [_vm._v(_vm._s(queryType))]
                          )
                        ]
                      },
                      proxy: true
                    }
                  ],
                  null,
                  true
                ),
                model: {
                  value: _vm.queryTypesCheckboxData[queryType],
                  callback: function($$v) {
                    _vm.$set(_vm.queryTypesCheckboxData, queryType, $$v)
                  },
                  expression: "queryTypesCheckboxData[queryType]"
                }
              })
            ],
            1
          )
        }),
        0
      ),
      _c("v-divider", { staticClass: "mb-5" }),
      _c("v-data-table", {
        attrs: {
          "footer-props": {
            itemsPerPageOptions: _vm.itemsPerPageOptions
          },
          headers: _vm.historyHeaders,
          items: _vm.queriesToDisplay,
          "items-per-page": _vm.itemsPerPage
        },
        on: {
          "update:itemsPerPage": function($event) {
            _vm.itemsPerPage = $event
          },
          "update:items-per-page": function($event) {
            _vm.itemsPerPage = $event
          }
        },
        scopedSlots: _vm._u([
          {
            key: "body",
            fn: function(ref) {
              var items = ref.items
              return [
                _c(
                  "tbody",
                  _vm._l(items, function(item) {
                    return _c("tr", { key: item.queryid }, [
                      _c("td", [
                        _c(
                          "span",
                          { staticClass: "subtitle-2 primary--text" },
                          [
                            _vm._v(
                              _vm._s(
                                item.mode_name ===
                                  _vm.queryModeOptions.PREVIEW_MODE
                                  ? "PREVIEW"
                                  : item.mode_name ===
                                    _vm.queryModeOptions.FULL_WITH_SAVE_MODE
                                  ? "FULL"
                                  : "-"
                              )
                            )
                          ]
                        )
                      ]),
                      _c("td", [
                        _c(
                          "div",
                          { staticClass: "d-flex justify-center" },
                          [
                            item.status_name !==
                            _vm.queryStatusOptions.FAILED_QUERY
                              ? _c(
                                  "v-tooltip",
                                  {
                                    attrs: { bottom: "" },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "activator",
                                          fn: function(ref) {
                                            var on = ref.on
                                            return [
                                              item.status_name ===
                                              _vm.queryStatusOptions
                                                .COMPLETED_QUERY
                                                ? _c(
                                                    "v-icon",
                                                    _vm._g(
                                                      {
                                                        attrs: {
                                                          color: "success",
                                                          dark: ""
                                                        }
                                                      },
                                                      on
                                                    ),
                                                    [_vm._v("check_circle")]
                                                  )
                                                : item.status_name ===
                                                  _vm.queryStatusOptions
                                                    .TERMINATED_QUERY
                                                ? _c(
                                                    "v-icon",
                                                    _vm._g(
                                                      {
                                                        attrs: {
                                                          color: "error",
                                                          dark: ""
                                                        }
                                                      },
                                                      on
                                                    ),
                                                    [_vm._v("mdi-cancel")]
                                                  )
                                                : item.status_name ===
                                                    _vm.queryStatusOptions
                                                      .RUNNING_QUERY ||
                                                  item.status_name ===
                                                    _vm.queryStatusOptions
                                                      .CREATED_QUERY ||
                                                  item.status_name ===
                                                    _vm.queryStatusOptions
                                                      .COPYING_QUERY
                                                ? _c(
                                                    "v-icon",
                                                    _vm._g(
                                                      {
                                                        attrs: {
                                                          color: "info",
                                                          dark: ""
                                                        }
                                                      },
                                                      on
                                                    ),
                                                    [_vm._v("mdi-timer-sand")]
                                                  )
                                                : _vm._e()
                                            ]
                                          }
                                        }
                                      ],
                                      null,
                                      true
                                    )
                                  },
                                  [
                                    _c("span", [
                                      _vm._v(_vm._s(item.status_name))
                                    ])
                                  ]
                                )
                              : _vm._e(),
                            item.status_name ===
                            _vm.queryStatusOptions.FAILED_QUERY
                              ? _c("QueryErrorDialog", {
                                  attrs: { qid: item.qid }
                                })
                              : _vm._e()
                          ],
                          1
                        )
                      ]),
                      _c(
                        "td",
                        [
                          _c("TheSnapshotTableQueryDialog", {
                            attrs: {
                              title: "SQL Text",
                              textToDisplay: item.command,
                              truncationLength: 65,
                              mode: item.mode_name,
                              isActiveUser: item.status_name,
                              query: item.command,
                              timePassed: _vm.diffHours(item.finished),
                              qid: item.qid.toString()
                            },
                            on: {
                              sqlText: function($event) {
                                return _vm.emitSqlText($event.value)
                              },
                              queryResultsSuccess: function($event) {
                                return _vm.$emit("queryResultsSuccess", {
                                  value: true
                                })
                              },
                              queryFilesSuccess: function($event) {
                                return _vm.$emit("queryFilesSuccess", {
                                  value: true
                                })
                              }
                            }
                          })
                        ],
                        1
                      ),
                      _c("td", [
                        _c("div", { staticClass: "d-flex flex-column py-3" }, [
                          _vm._v(
                            " " +
                              _vm._s(_vm._f("dateTimeToHuman")(item.created)) +
                              " "
                          ),
                          _c(
                            "span",
                            {
                              staticClass:
                                "caption primary--text font-weight-bold"
                            },
                            [
                              _vm._v(
                                "Duration: " +
                                  _vm._s(
                                    _vm.calculateDuration(
                                      item.finished,
                                      item.created
                                    )
                                  )
                              )
                            ]
                          )
                        ])
                      ]),
                      _c(
                        "td",
                        [
                          item.status_name ===
                          _vm.queryStatusOptions.RUNNING_QUERY
                            ? _c(
                                "v-tooltip",
                                {
                                  attrs: { bottom: "" },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "activator",
                                        fn: function(ref) {
                                          var on = ref.on
                                          return [
                                            _c(
                                              "v-btn",
                                              _vm._g(
                                                {
                                                  staticClass:
                                                    "font-weight-bold",
                                                  attrs: {
                                                    icon: "",
                                                    color: "primary"
                                                  },
                                                  on: {
                                                    click: function($event) {
                                                      return _vm.cancelQuery(
                                                        item.qid
                                                      )
                                                    }
                                                  }
                                                },
                                                on
                                              ),
                                              [
                                                _c(
                                                  "v-icon",
                                                  { attrs: { small: "" } },
                                                  [_vm._v("mdi-square")]
                                                )
                                              ],
                                              1
                                            )
                                          ]
                                        }
                                      }
                                    ],
                                    null,
                                    true
                                  )
                                },
                                [_c("span", [_vm._v("Cancel query")])]
                              )
                            : item.status_name ===
                                _vm.queryStatusOptions.FAILED_QUERY ||
                              (item.status_name ===
                                _vm.queryStatusOptions.COMPLETED_QUERY &&
                                item.mode_name ===
                                  _vm.queryModeOptions.PREVIEW_MODE &&
                                _vm.diffHours(item.finished) > 1440) ||
                              (item.status_name ===
                                _vm.queryStatusOptions.COMPLETED_QUERY &&
                                item.mode_name ===
                                  _vm.queryModeOptions.FULL_WITH_SAVE_MODE &&
                                _vm.diffHours(item.finished) > 2880)
                            ? _c("QuerySubmit", {
                                attrs: {
                                  mode: item.mode_name,
                                  query: item.command,
                                  tooltipHint: "Rerun query",
                                  iconShaped: true,
                                  forDialog: false
                                }
                              })
                            : item.status_name ===
                                _vm.queryStatusOptions.COMPLETED_QUERY &&
                              item.mode_name ===
                                _vm.queryModeOptions.PREVIEW_MODE
                            ? _c("QueryViewResults", {
                                attrs: {
                                  qid: item.qid.toString(),
                                  sql: item.command,
                                  forDialog: false
                                },
                                on: {
                                  queryResultsSuccess: function($event) {
                                    return _vm.$emit("queryResultsSuccess", {
                                      value: true
                                    })
                                  }
                                }
                              })
                            : item.status_name ===
                                _vm.queryStatusOptions.COMPLETED_QUERY &&
                              item.mode_name ===
                                _vm.queryModeOptions.FULL_WITH_SAVE_MODE
                            ? _c("QueryGetFilesButton", {
                                attrs: {
                                  qid: item.qid.toString(),
                                  forDialog: false
                                },
                                on: {
                                  queryFilesSuccess: function($event) {
                                    return _vm.$emit("queryFilesSuccess", {
                                      value: true
                                    })
                                  }
                                }
                              })
                            : _vm._e()
                        ],
                        1
                      )
                    ])
                  }),
                  0
                )
              ]
            }
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }