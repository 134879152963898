<template>
    <div>
        <div class="d-flex flex-wrap align-center">
            <div v-for="queryType in availableQueryTypes" :key="queryType">
                <v-checkbox :label="queryType" class="mx-4" v-model="queryTypesCheckboxData[queryType]">
                    <template v-slot:label>
                        <span class="primary--text caption text-uppercase">{{ queryType }}</span>
                    </template>
                </v-checkbox>
            </div>
        </div>
        <v-divider class="mb-5"></v-divider>
        <v-data-table
            :footer-props="{
                itemsPerPageOptions: itemsPerPageOptions
            }"
            :headers="historyHeaders"
            :items="queriesToDisplay"
            :items-per-page.sync="itemsPerPage"
        >
            <template v-slot:body="{ items }">
                <tbody>
                    <tr v-for="item in items" :key="item.queryid">
                        <td>
                            <span class="subtitle-2 primary--text">{{
                                item.mode_name === queryModeOptions.PREVIEW_MODE
                                    ? 'PREVIEW'
                                    : item.mode_name === queryModeOptions.FULL_WITH_SAVE_MODE
                                    ? 'FULL'
                                    : '-'
                            }}</span>
                        </td>
                        <td>
                            <div class="d-flex justify-center">
                                <v-tooltip v-if="item.status_name !== queryStatusOptions.FAILED_QUERY" bottom>
                                    <template v-slot:activator="{ on }">
                                        <v-icon v-if="item.status_name === queryStatusOptions.COMPLETED_QUERY" color="success" dark v-on="on"
                                            >check_circle</v-icon
                                        >
                                        <v-icon v-else-if="item.status_name === queryStatusOptions.TERMINATED_QUERY" color="error" dark v-on="on"
                                            >mdi-cancel</v-icon
                                        >
                                        <v-icon
                                            v-else-if="
                                                item.status_name === queryStatusOptions.RUNNING_QUERY ||
                                                    item.status_name === queryStatusOptions.CREATED_QUERY ||
                                                    item.status_name === queryStatusOptions.COPYING_QUERY
                                            "
                                            color="info"
                                            dark
                                            v-on="on"
                                            >mdi-timer-sand</v-icon
                                        >
                                    </template>
                                    <span>{{ item.status_name }}</span>
                                </v-tooltip>
                                <QueryErrorDialog :qid="item.qid" v-if="item.status_name === queryStatusOptions.FAILED_QUERY"></QueryErrorDialog>
                            </div>
                        </td>
                        <td>
                            <TheSnapshotTableQueryDialog
                                title="SQL Text"
                                :textToDisplay="item.command"
                                :truncationLength="65"
                                :mode="item.mode_name"
                                :isActiveUser="item.status_name"
                                :query="item.command"
                                :timePassed="diffHours(item.finished)"
                                @sqlText="emitSqlText($event.value)"
                                @queryResultsSuccess="$emit('queryResultsSuccess', { value: true })"
                                @queryFilesSuccess="$emit('queryFilesSuccess', { value: true })"
                                :qid="item.qid.toString()"
                            />
                        </td>
                        <td>
                            <div class="d-flex flex-column py-3">
                                {{ item.created | dateTimeToHuman }}
                                <span class="caption primary--text font-weight-bold">Duration: {{ calculateDuration(item.finished, item.created) }}</span>
                            </div>
                        </td>
                        <td>
                            <v-tooltip v-if="item.status_name === queryStatusOptions.RUNNING_QUERY" bottom>
                                <template v-slot:activator="{ on }">
                                    <v-btn @click="cancelQuery(item.qid)" v-on="on" icon class="font-weight-bold" color="primary">
                                        <v-icon small>mdi-square</v-icon>
                                    </v-btn>
                                </template>
                                <span>Cancel query</span>
                            </v-tooltip>
                            <QuerySubmit
                                v-else-if="
                                    item.status_name === queryStatusOptions.FAILED_QUERY ||
                                        (item.status_name === queryStatusOptions.COMPLETED_QUERY &&
                                            item.mode_name === queryModeOptions.PREVIEW_MODE &&
                                            diffHours(item.finished) > 1440) ||
                                        (item.status_name === queryStatusOptions.COMPLETED_QUERY &&
                                            item.mode_name === queryModeOptions.FULL_WITH_SAVE_MODE &&
                                            diffHours(item.finished) > 2880)
                                "
                                :mode="item.mode_name"
                                :query="item.command"
                                tooltipHint="Rerun query"
                                :iconShaped="true"
                                :forDialog="false"
                            ></QuerySubmit>
                            <QueryViewResults
                                v-else-if="item.status_name === queryStatusOptions.COMPLETED_QUERY && item.mode_name === queryModeOptions.PREVIEW_MODE"
                                :qid="item.qid.toString()"
                                :sql="item.command"
                                @queryResultsSuccess="$emit('queryResultsSuccess', { value: true })"
                                :forDialog="false"
                            ></QueryViewResults>
                            <QueryGetFilesButton
                                v-else-if="item.status_name === queryStatusOptions.COMPLETED_QUERY && item.mode_name === queryModeOptions.FULL_WITH_SAVE_MODE"
                                :qid="item.qid.toString()"
                                @queryFilesSuccess="$emit('queryFilesSuccess', { value: true })"
                                :forDialog="false"
                            ></QueryGetFilesButton>
                        </td>
                    </tr>
                </tbody>
            </template>
        </v-data-table>
    </div>
</template>
<script>
import { calculateDuration } from '@/utils'
const QuerySubmit = () => import('./TheSnapshotSubmitQuery')
const TheSnapshotTableQueryDialog = () => import('./TheSnapshotTableQueryDialog')
const QueryErrorDialog = () => import('./TheSnapshotQueryErrorDialog')
const QueryViewResults = () => import('./TheSnapshotQueryViewResults')
const QueryGetFilesButton = () => import('./TheSnapshotQueryGetFilesButton')
export default {
    name: 'QueryHistory',
    components: {
        QuerySubmit,
        TheSnapshotTableQueryDialog,
        QueryErrorDialog,
        QueryViewResults,
        QueryGetFilesButton
    },
    props: {
        snapshotQueries: Array
    },
    data() {
        return {
            queryPreviewLoading: false,
            errorDialog: false,
            itemsPerPage: 25,
            itemsPerPageOptions: [25, 50, 100, -1],
            loading: false,
            historyHeaders: [
                { text: 'Type', align: 'left', value: 'type', width: '50' },
                { text: 'Status', value: 'status', align: 'left', width: '40px' },
                { text: 'SQL Text', value: 'sqltext', width: '400px' },
                {
                    text: 'Start Time (UTC)',
                    value: 'startTime',
                    align: 'left',
                    width: '170px'
                },
                { text: 'Actions', align: 'end', value: 'actions', width: '80px' }
            ],
            queryStatusOptions: {
                RUNNING_QUERY: 'RUNNING',
                FAILED_QUERY: 'FAILED',
                COMPLETED_QUERY: 'COMPLETED',
                TERMINATED_QUERY: 'TERMINATED',
                CREATED_QUERY: 'CREATED',
                COPYING_QUERY: 'COPYING'
            },
            queryModeOptions: {
                PREVIEW_MODE: 'PREVIEW',
                FULL_QUERY_MODE: 'FULL',
                FULL_WITH_SAVE_MODE: 'FULL_WITH_SAVE'
            },
            queryTypesCheckboxData: {
                RUNNING: true,
                FAILED: true,
                COMPLETED: true,
                TERMINATED: true,
                CREATED: true,
                COPYING: true
            }
        }
    },
    computed: {
        availableQueryTypes() {
            if (this.$props.snapshotQueries) {
                const queryTypes = this.$props.snapshotQueries.map(query => query.status_name)
                const uniqueQueryTypes = [...new Set(queryTypes)]
                return uniqueQueryTypes
            }
            return []
        },
        queriesToDisplay() {
            if (this.$props.snapshotQueries) {
                const selectedQueryTypes = Object.entries(this.$data.queryTypesCheckboxData)
                    .filter(queryType => queryType[1] === true)
                    .map(queryType => queryType[0])
                const filteredQueries = this.$props.snapshotQueries.filter(query => selectedQueryTypes.includes(query.status_name))
                return filteredQueries
            }
            return []
        }
    },
    methods: {
        cancelQuery: function(qid) {
            this.$axios
                .delete(`/queries/${qid}`)
                .then(() => {
                    this.$store.dispatch('showSnackBar', {
                        snackBarText: 'Cancelling query..',
                        snackBarTimeout: 10000,
                        snackBarIcon: 'info'
                    })
                })
                .catch(() => {
                    this.$store.dispatch('showSnackBar', {
                        snackBarText: 'Query cancellation failed, please try again later..',
                        snackBarTimeout: 10000,
                        snackBarIcon: 'error'
                    })
                })
        },
        diffHours: function(dt) {
            const today = new Date()
            const date2 = new Date(dt)
            var diff = (today.getTime() - date2.getTime()) / 1000
            diff /= 60
            return Math.abs(Math.round(diff))
        },
        calculateDuration: function(date2, date1) {
            return calculateDuration(date2, date1)
        },
        emitSqlText(sql) {
            this.$emit('sqlText', { value: sql })
        },
        dateDifference(firstDate, secondDate) {
            if (secondDate) {
                const date1 = new Date(firstDate)
                const date2 = new Date(secondDate)
                return date2 - date1
            } else {
                return '-'
            }
        }
    }
}
</script>
